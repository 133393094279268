<template>
  <Modal v-if="user" :class="[selectedNewParent !== 'new' && currentSelectedParent && currentSelectedParent.age < 16 ? 'add-parent-modal' : '']" :title="!familyMembers.length || selectedNewParent === 'new' ? 'Maak een voogd/ouder aan' : 'Duid een voogd/ouder aan'" :closable="false" ref="addParentModal">
    <Form >
      <p>Voor het verwerken van gegevens van minderjarigen moet de account op naam van een voogd/ouder staan. Gelieve de voogd aan te duiden of aan te maken.</p>
      <FormGroup v-if="familyMembers.length" >
        <Dropdown :placeholder="{ label: 'Kies een voogd/ouder' }" :items="newParentOptions" @change="selectParent" value=""  />
        <p v-if="selectedNewParent !== 'new' && currentSelectedParent.age < 16" class="text-danger">Voogd/ouder moet minstens 16 jaar zijn</p>
      </FormGroup>
      <FormGroup v-if="currentSelectedParent && selectedNewParent !== 'new' && currentSelectedParent.hasOwnProperty('age') && currentSelectedParent.age < 16">
        <Datepicker v-if="currentSelectedParent && familyMemberBirthdate" @opened="test" :label="$t('Geboortedatum ' + currentSelectedParent.fname + ' ' + currentSelectedParent.lname)" id="birthdateFamilyMember" ref="datepicker" :v="$v.familyMemberBirthdate" v-model="familyMemberBirthdate" :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }" />
      </FormGroup>
      <FormGroup v-if="!familyMembers.length || selectedNewParent === 'new'">
        <Datepicker :label="$t('Geboortedatum voogd/ouder')" id="birthdateParent" :v="$v.newParentBirthdate" v-model="newParentBirthdate" :key="updateDatepicker" :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }" />
        <Input :label="$t('Voornaam voogd/ouder')" :v="$v.newParentFirstname" v-model="newParentFirstname"/>
        <Input :label="$t('Achternaam voogd/ouder')" :v="$v.newParentLastname" v-model="newParentLastname" />
        <Phone :label="$t('Telefoonnummer voogd/ouder')" id="phone" :v="$v.newParentPhone" v-model="newParentPhone" />
      </FormGroup>
      <div class="add-parent-modal-button-wrapper">
        <Button danger @click="logout">Uitloggen</Button>
        <Button primary @click="addNewParent">Opslaan</Button>
      </div>

    </Form>
  </Modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { moreThanYearsAgo, isPhone, isValidName } from '../../utils/validators'
import Modal from '../Core/Modal.vue'
import Input from '../Core/Input'
import Button from '../Core/Button'
import Form from '../Core/Form'
import Datepicker from '../Core/Datepicker'
import Phone from '../Core/Phone'
import Dropdown from '../Core/Dropdown'
import FormGroup from '../Core/FormGroup'
import api from '../../api'
import moment from 'moment'

export default {
  name: '',
  components: {
    Modal,
    Input,
    Datepicker,
    Button,
    Phone,
    Form,
    Dropdown,
    FormGroup
  },
  data () {
    return {
      newParent: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        birthdate: ''
      },
      newParentFirstname: '',
      newParentLastname: '',
      newParentBirthdate: '',
      newParentPhone: '',
      showChooseParentDropdown: false,
      selectedNewParent: '',
      familyMemberBirthdate: '',
      loaded: false,
      updateDatepicker: 0
    }
  },
  mounted () {
    moment.locale('nl')
  },
  validations () {
    const validations = {}
    if (this.selectedNewParent === 'new') {
      validations.newParentFirstname = {
        required,
        isValidName
      }
      validations.newParentLastname = {
        required,
        isValidName
      }
      validations.newParentBirthdate = {
        required,
        minAge: moreThanYearsAgo(16)
      }
      validations.newParentPhone = {
        required,
        isPhone
      }
    } else {
      validations.selectedNewParent = {
        required
      }
      if (this.selectedNewParent && this.currentSelectedParent.age < 16) {
        validations.familyMemberBirthdate = {
          required,
          minAge: moreThanYearsAgo(16)
        }
      }
    }
    return validations
  },
  computed: {
    user () {
      return this.$store.getters['user/user']
    },
    familyMembers () {
      return this.$store.getters['user/users']
    },
    newParentOptions () {
      if (!this.familyMembers.length) return []

      const options = this.familyMembers.map((user) => {
        return {
          key: user.uid,
          value: user.name + ' (' + user.birthdate.format('DD/MM/YYYY') + ')'
        }
      })
      options.push({ key: 'new', value: 'Maak een voogd/ouder aan' })
      return options
    },
    currentSelectedParent () {
      if (!this.familyMembers.length || !this.selectedNewParent) return false

      return this.familyMembers.find((familymember) => {
        return parseInt(familymember.uid) === parseInt(this.selectedNewParent)
      })
    },
    isOldEnoughToBeParent () {
      const today = moment()
      const age = today.diff(this.currentSelectedParent.birthdate, 'years')
      if (age >= 16) return true
      else return false
    }
  },
  methods: {
    async addNewParent () {
      this.$v.$touch()

      if (this.$v.familyMemberBirthdate && this.$v.familyMemberBirthdate.$error) {
        this.$store.commit('notice/addNotices', {
          title: this.$t('Mislukt'),
          message: this.$t('Geboortedatum is niet ingevuld of geboortedatum is jonger dan 16 jaar'),
          type: 'error'
        })
      } else if (this.$v.$anyError) {
        this.$store.commit('notice/addNotices', {
          title: this.$t('Mislukt'),
          message: this.$t(this.selectedNewParent === 'new' ? 'Gegevens van voogd/ouder zijn niet juist ingevuld' : 'Geen voogd/ouder geselecteerd'),
          type: 'error'
        })
      } else {
        const args = {}

        if (this.selectedNewParent === 'new') {
          args.uid = this.user.uid
          args.fname = this.newParentFirstname
          args.lname = this.newParentLastname
          args.birthdate = this.newParentBirthdate
          args.phone = this.newParentPhone
        } else {
          args.setFamilyMemberAsParent = 1
          args.uid = this.selectedNewParent
          if (this.familyMemberBirthdate) args.birthdate = this.familyMemberBirthdate
        }
        console.log({ args })
        try {
          const response = await api.post('add_new_parent', {
            ...args
          })

          this.$refs.addParentModal.hide()
          this.$store.commit('notice/addNotices', {
            title: this.$t('Even geduld'),
            message: this.$t('Bezig met opslaan...'),
            type: 'info'
          })

          const responseValidateAuth = await api.post('validate_auth', { auth: response.data.auth })

          await this.$store.commit('user/setUser', {
            ...responseValidateAuth.data.user,
            auth: response.data.auth
          })

          this.$store.commit('notice/addNotices', {
            title: this.$t(this.selectedNewParent ? 'Voogd/ouder opslaan' : 'Voogd/ouder toevoegen'),
            message: this.$t(this.selectedNewParent ? 'Voogd/ouder is opgeslaan' : 'Nieuwe voogd/ouder is toegevoegd'),
            type: 'success'
          })
        } catch (error) {
          this.$store.commit('notice/addNotices', {
            title: this.$t('Mislukt'),
            message: this.$t(error.message),
            type: 'error'
          })
        }
      }
    },
    selectParent (key) {
      this.selectedNewParent = key
    },
    async logout () {
      await this.$store.dispatch('user/logout')
    }
  },
  watch: {
    user () {
      if (this.user.age < 16) setTimeout(() => { this.$refs.addParentModal.show() }, 200)
    },
    currentSelectedParent () {
      this.familyMemberBirthdate = false
      if (this.currentSelectedParent && this.currentSelectedParent.age < 16) this.familyMemberBirthdate = this.currentSelectedParent.birthdate.format('YYYY/MM/DD')
      this.updateDatepicker++
    }
  }
}
</script>

<style>
  .add-parent-modal-button-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .add-parent-modal .modal-content {
    overflow-y: unset;
  }

  @media screen and (max-width: 650px) {
    .add-parent-modal-button-wrapper:first-child {
      margin-right: 10px;
    }
  }
</style>
