import moment from 'moment'
import store from '../store/index'
import api from '../api'
import Vue from 'vue'
import i18n from '../i18n'

export const calcAgeRestrictions = (course) => {
  if (course.ageRestrictions && course.ageRestrictions.reduce((a, b) => parseInt(a) + parseInt(b))) {
    return [course.ageRestrictions[0], course.ageRestrictions[1]]
  }
  return false
}

export const calcAgeRestrictionsByYear = (course) => {
  if (course.ageRestrictionsByYear && course.ageRestrictionsByYear.reduce((a, b) => parseInt(a) + parseInt(b))) {
    return [course.ageRestrictionsByYear[0], course.ageRestrictionsByYear[1]]
  }
  return false
}

export const isAgeRestricted = (selectedUser, course) => {
  if (!course) return false
  if (!selectedUser) return false
  const age = parseInt(selectedUser.age)
  const birthyear = parseInt(selectedUser.birthdate.format('Y'))

  const ageRestrictions = calcAgeRestrictions(course)
  const ageRestrictionsByYear = calcAgeRestrictionsByYear(course)

  if (ageRestrictions) {
    return age < parseInt(ageRestrictions[0]) || age > parseInt(ageRestrictions[1])
  } else if (ageRestrictionsByYear) {
    return birthyear < parseInt(ageRestrictionsByYear[0]) || birthyear > parseInt(ageRestrictionsByYear[1])
  }
  return false
}

export const isAlreadyRegistered = (selectedUser, tariff) => {
  if (!selectedUser) return false
  return parseInt(tariff.registeredUsersPerTariff[selectedUser.uid]?.status) > 0 || parseInt(tariff.registeredUsersPerTariff[selectedUser.uid]?.status) === -99
}

export const isQueued = (selectedUser, tariff) => {
  if (!selectedUser) return false
  return parseInt(tariff.queuedUsersPerTariff[selectedUser.uid]) > 0
}

export const isBlocked = (tariff) => {
  return tariff.blockedRegistrationMessage
}

export const canRegister = (selectedUser, tariff) => {
  return !isAlreadyRegistered(selectedUser, tariff) && !isQueued(selectedUser, tariff) && !isBlocked(tariff) && !(tariff.availability <= 0 && tariff.disable_waiting_list)
}

export const hasOverlap = (tariff, tariffs) => {
  if (tariff.course.type === 'activity') return false
  const startTariff = moment(tariff.start)
  const endTariff = moment(tariff.end)
  const overlappingItems = tariffs.filter(tmpTariff => {
    if (parseInt(tariff.nid) === parseInt(tmpTariff.nid)) return false // Not same tariff, do not count as overlap
    if (parseInt(tariff.course.nid) !== parseInt(tmpTariff.course.nid)) return false // Not same course, so no overlap
    const startTariffItem = moment(tmpTariff.start, 'D MMMM YYYY')
    const endTariffItem = moment(tmpTariff.end, 'D MMMM YYYY')

    if (startTariff.isBetween(startTariffItem, endTariffItem, 'minutes', '[]')) return true
    if (endTariff.isBetween(startTariffItem, endTariffItem, 'minutes', '[]')) return true
    if (startTariffItem.isBetween(startTariff, endTariff, 'minutes', '[]')) return true
    if (endTariffItem.isBetween(startTariff, endTariff, 'minutes', '[]')) return true
    return false
  })
  return overlappingItems.length > 0
}

export const isFieldFilled = (field) => {
  if (!field.answer?.length && field.type !== 'address') {
    return false
  }
  if (field.type === 'select') {
    if (!field.options.includes(field.answer)) return false
  }
  if (field.type === 'select_multiple') {
    // Todo handle select multiple
  }
  if (field.type === 'address') {
    if (typeof field.answer !== 'object') return false
    return field.answer?.street?.length > 0 && field.answer?.streetNumber?.length > 0 && field.answer?.city?.length > 0 && field.answer?.postal?.length > 0 && field.answer?.country?.length > 0
  }
  return true
}

export const openInvoice = (oid, uid, club, creditNote = false) => {
  window.open(`${api.getUrl()}?q=${creditNote ? 'view_credit_note' : 'view_invoice'}&auth=${store.getters['user/auth']}&oid=${oid}&uid=${uid}&club=${club}`, '_blank', 'height=500,width=800,location=no,menubar=no,resizable=yes,scrollbars=yes,status=no,titlebar=yes,toolbar=no')
}

export const payInvoice = (oid) => {
  window.open(`${api.getUrl()}?q=redirect_to_mollie_payment&oid=${oid}`, '_blank', 'height=500,width=800,location=no,menubar=no,resizable=yes,scrollbars=yes,status=no,titlebar=yes,toolbar=no')
}

export const scrollTo = (selector = false, timeout = 0) => {
  Vue.nextTick(() => {
    setTimeout(() => {
      if (selector) {
        const element = document.querySelector(selector)
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth'
          })
        }
      } else {
        window.scroll({ top: 0, behavior: 'smooth' })
      }
    }, timeout)
  })
}

export const formatTime = (time, last = false) => {
  const timeObject = moment(time, 'HH:mm')
  return timeObject.format(timeObject.minute() > 0 ? 'H[u]mm' : last ? 'H[u]' : 'H')
}

export const fullTime = (time) => {
  if (time.length === 5) return time
  return '0' + time
}

export const dayString = (weekdays) => {
  if (typeof weekdays === 'string') return weekdays
  weekdays = weekdays.map(weekday => {
    return {
      ...weekday,
      timestamps: weekday.timestamps.map(timestamp => {
        return timestamp.split(';')
      })
    }
  })
  let timestamps = []
  for (const weekday of weekdays) {
    timestamps = timestamps.concat(weekday.timestamps.map(timestamp => timestamp.join(';')))
  }
  timestamps = Array.from(new Set(timestamps))
  if (weekdays.length > 3) {
    return i18n.t('meerdere dagen ')
  }
  if (weekdays.length === 1) {
    return i18n.t(`weekdays.${weekdays[0].day}`) + ' ' + (weekdays[0].timestamps[0] ? (i18n.t('course.time', { from: formatTime(weekdays[0].timestamps[0][0]), to: formatTime(weekdays[0].timestamps[0][1], true) })) : '')
  }
  return weekdays.map(weekday => i18n.t(`weekdays.${weekday.day}`) + (timestamps.length > 1 || !weekday.timestamps[0] ? ' ' + i18n.t('course.time', { from: formatTime(weekday.timestamps[0][0]), to: formatTime(weekday.timestamps[0][1], true) }) : '')).join(weekdays.length === 2 ? ' en ' : ', ') + ' ' + (timestamps.length === 1 && weekdays[0].timestamps[0] ? i18n.t('course.time', { from: formatTime(weekdays[0].timestamps[0][0]), to: formatTime(weekdays[0].timestamps[0][1], true) }) : '')
}

export const downloadFiles = async (url, filename, loadingMessage = false, data) => {
  const response = await api.post(url, data, {
    responseType: 'blob'
  })

  const blob = new Blob([response.data])

  if (response.data.type === 'application/javascript' || response.data.type === 'application/json') {
    const APIResult = JSON.parse(await blob.text())

    if (APIResult.errors) {
      APIResult.errors.forEach((error) => {
        store.commit('notice/addNotices', {
          title: 'Er is iets mislukt',
          message: error,
          type: 'error'
        })
      })
    }
  } else {
    const objectUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = objectUrl
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }
}
