<template>
  <p class="form-error">
    <slot v-if="hasMessageSlot"/>
    <span v-else>{{ error }}<a href="#" v-if="$listeners.override" @click.prevent="$emit('override')">, <strong>foutmelding negeren</strong></a></span>
  </p>
</template>

<script>
export default {
  props: {
    v: {
      type: Object
    }
  },
  computed: {
    hasMessageSlot () {
      return Object.keys(this.$slots).length > 0
    },
    error () {
      if (!this.v) {
        return false
      }
      if ('required' in this.v && !this.v.required) {
        return this.$t('Vul dit veld in')
      }
      if ('email' in this.v && !this.v.email) {
        return this.$t('Vul een geldig e-mailadres in')
      }
      if ('uniqueEmail' in this.v && !this.v.unique) {
        return this.$t('Dit e-mailadres is reeds in gebruikt, vul een ander e-mailadres in of log in')
      }
      if ('phone' in this.v && !this.v.phone) {
        return this.$t('Dit is geen geldig telefoonnummer')
      }
      if ('validateField' in this.v && !this.v.validateField) {
        return this.$t('Dit is geen geldige waarde voor dit veld.')
      }
      if ('minAge' in this.v && !this.v.minDate) {
        return this.$t('form.min_age', { age: this.v.$params.minAge.value })
      }
      if ('hasAccepted' in this.v && !this.v.hasAccepted) {
        return this.$t('form.has_accepted')
      }
      if ('emails' in this.v && !this.v.emails) {
        return this.$t('Niet alle waarden zijn geldige e-mailadressen')
      }
      if ('sameAsPassword' in this.v && !this.v.sameAsPassword) {
        return this.$t('Wachtwoorden komen niet overeen')
      }
      if ('isPhone' in this.v && !this.v.isPhone) {
        return this.$t('Geen geldig telefoonnummer')
      }
      if ('notSameAsPrimaryEmail' in this.v && !this.v.isPhone) {
        return this.$t('Mag niet zelfde zijn als primair e-mailadres')
      }
      if ('isValidAddress' in this.v && !this.v.isValidAddress) {
        return this.$t('Dit is geen geldig adres, gelieve deze te controleren')
      }
      if ('isBirthdate' in this.v && !this.v.isBirthdate) {
        return this.$t('Geen geldige geboortedatum')
      }
      if ('notEqualToMainEmail' in this.v && !this.v.notEqualToMainEmail) {
        return this.$t('Mag niet gelijk zijn aan eigen e-mailadres, kies hiervoor een gezinslid')
      }
      if ('uniqueUitpas' in this.v && !this.v.uniqueUitpas) {
        return this.$t('Je kan niet twee keer hetzelfde UiTPAS nummer gebruiker')
      }
      if ('minValue' in this.v && !this.v.minValue) {
        return this.$t('Waarde mag niet kleiner zijn dan ' + this.v.$params.minValue.min)
      }
      if ('maxValue' in this.v && !this.v.maxValue) {
        return this.$t('Waarde mag niet groter zijn dan ' + this.v.$params.maxValue.max)
      }
      if ('validPassword' in this.v && !this.v.validPassword) {
        return this.$t('Wachtwoord komt niet overeen met account')
      }
      if ('isValidEmail' in this.v && !this.v.isValidEmail) {
        return this.$t('Vul een geldig e-mailadres in')
      }
      if ('isValidName' in this.v && !this.v.isValidName) {
        return this.$t('Vul een geldige naam in')
      }
      return this.$t('Er is iets misgegaan')
    }
  }
}
</script>

<style lang="scss">
.form-error {
  color: var(--danger-color);
  margin-top: 0.4rem;
}
</style>
